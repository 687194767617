import React, { useCallback } from 'react';
import type { ButtonProps } from './Button.types';
import { ArrowLeft, ArrowRight, StyledButton } from './styles';
import { Stack, Typography } from '@mui/material';
import { useRouterEventually } from '../../../hooks';

// TODO: refactor to use next Link instead of onClick
export const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  size = 'medium',
  iconDirection = 'right',
  typographyVariant = 'strong_300',
  link,
  ...props
}) => {
  const shouldHaveText = variant !== 'flat';
  const shouldHaveArrow = variant === 'flat' || variant === 'link';
  const Arrow = iconDirection === 'right' ? ArrowRight : ArrowLeft;

  const router = useRouterEventually();
  const handleClick = useCallback(
    (link: string | undefined) => {
      if (!link) return;
      if (router) {
        router.push(link);
      } else {
        window.location.href = link;
      }
    },
    [router]
  );
  return (
    <StyledButton disableRipple variant={variant} size={size} {...props}>
      <Stack onClick={() => handleClick(link)}>
        {variant === 'icon' ? (
          <>{children}</>
        ) : (
          <>
            {shouldHaveText && (
              <Typography
                variant={typographyVariant}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                {children}
              </Typography>
            )}
            {shouldHaveArrow && <Arrow />}
          </>
        )}
      </Stack>
    </StyledButton>
  );
};
