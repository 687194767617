import { useTheme, styled } from '@mui/material';
import React from 'react';
import { NavLink, Button } from '@rumblefish/ui';
import { NavigationButtonProps } from './NavigationButton.types';
import { ScreenReaderText } from '../../ScreenReaderText';

type PaletteColors = {
  [key: string]: {
    PLUS_1?: string;
    PLUS_2?: string;
  };
};

export const NavigationButton: React.FC<NavigationButtonProps> = ({
  target,
  buttonText,
  className,
  ariaLabel,
  color,
}) => {
  const theme = useTheme();
  const palette = theme.palette as unknown as PaletteColors;

  const buttonColor: string = palette?.[`${color}Colors`]?.PLUS_2 ?? 'white';
  const hoverColor: string = palette?.[`${color}Colors`]?.PLUS_1 ?? 'white';

  const StyledButton = styled(Button)(() => ({
    backgroundColor: 'transparent !important',
    color: 'white',
    border: '1px solid white',
    borderRadius: '30px',
    paddingInline: '30px',
    paddingBlock: '8px',
    textTransform: 'none',
    lineHeight: '28px',
    fontSize: '16px',
    letterSpacing: '-0.1px',
    fontWeight: '600',
    boxShadow: `0px 0px 6px 4px ${buttonColor}, 0px 0px 6px 4px ${buttonColor} inset !important`,
    [`&:hover`]: {
      boxShadow: `0px 0px 6px 4px ${hoverColor}, 0px 0px 6px 4px ${hoverColor} inset !important`,
    },
  }));
  return (
    <NavLink exact to={target}>
      <StyledButton className={className}>
        {buttonText}
        <ScreenReaderText> {ariaLabel} </ScreenReaderText>
      </StyledButton>
    </NavLink>
  );
};
